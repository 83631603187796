import React  from 'react';
import { LineFormField } from 'psCMS-common';
import { withTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

var __reCaptchaToken__ = null // Settings this as a global ensures that the token is only ever set once per CRA instancee.

export default withTranslation('contact')(class Contact extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			body: '',
			isLoading: false,
			reCaptchaToken: __reCaptchaToken__
		}
	}


	handleNameChange(e) {this.setState({name: e.target.value})};
	handleEmailChange(e) {this.setState({email: e.target.value})};
	handleBodyChange(e) {this.setState({body: e.target.value})};


	setReCaptchaToken(reCaptchaToken) {
		__reCaptchaToken__ = reCaptchaToken;
		this.setState({reCaptchaToken:reCaptchaToken})
	}


	handleSubmit(e) {
		e.preventDefault();
		this.setState({isLoading: true})
		fetch('/contact', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(this.state)
		})
		.then(res => {
			// Unfortunately, fetch doesn't send (404 error) into the cache itself
			if(res.status >= 400) {
				console.log(res)
				throw new Error(`Server responded with error ${res.status}!`);
			}
			return res.json();
		})
		.then((data) => {
			window.addNotification('success', this.props.t('Message sent!'))
			this.setState({
				isLoading: false
			})
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components
		err => {
			console.log(err)
			window.addNotification('error', this.props.t('Failed to send message.'))
			this.setState({
				isLoading: false
			})
		});
	}


	render() {
		return (
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
				language={this.props.i18n.language}
				useRecaptchaNet={true}
				useEnterprise={true}
				scriptProps={{
					async: false, // optional, default to false,
					defer: false, // optional, default to false
					appendTo: 'head', // optional, default to "head", can be "head" or "body",
					nonce: undefined // optional, default undefined
				}}>
				<form className="ps_lineForm ps_pop" onSubmit={this.handleSubmit.bind(this)}>
					<p className="ps_lineForm_header ps_pop">{this.props.t('Message me')}</p>
					<input type="hidden" name="terms_accept" value="1" />
					<input type="hidden" name="c1" value="contact" />
					<LineFormField
						id={"name"}
						name={"name"}
						value={this.state.name}
						required={true}
						maxLength={100}
						onChange={this.handleNameChange.bind(this)}
						description={this.props.t('Your name')}
					/>
					<LineFormField
						id={"email"}
						name={"email"}
						type={"email"}
						value={this.state.email}
						required={true}
						maxLength={100}
						onChange={this.handleEmailChange.bind(this)}
						description={this.props.t('Your E-mail')}
					/>
					<LineFormField
						id={"body"}
						name={"body"}
						type={"textarea"}
						value={this.state.body}
						required={true}
						maxLength={1000}
						onChange={this.handleBodyChange.bind(this)}
						cols={"90"}
						rows={"7" }
						spellCheck={true}
						description={this.props.t('Enter your message here')}
					/>
					{this.state.reCaptchaToken===null ?
						<GoogleReCaptcha 
							onVerify={this.setReCaptchaToken.bind(this)}
						/>
					: '' }
					<div>
						<input 
							type="submit" 
							name="submit" 
							value={this.props.t('Submit')} 
							className={"button" + (this.state.reCaptchaToken===null || this.state.isLoading===true ? ' disabled' : '')}
							disabled={this.state.reCaptchaToken===null || this.state.isLoading===true ? 'disabled' : ''}
						/>
					</div>
				</form>
				</GoogleReCaptchaProvider>
		);
	}
})