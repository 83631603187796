import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Button from 'react-bootstrap/Button';


export default class Pagination extends React.PureComponent {
  static defaultProps = {
    activePage: 1,
    maxPage: 1,
    maxPageButtonsToShow: 5, 
    setPage: (page) => {}
  };


  getPagesBeforeActivePage() {
    var lowestPage = Math.max(1,this.props.activePage-this.props.maxPageButtonsToShow);
    return Array(this.props.activePage-lowestPage).fill(lowestPage).map((x, y) => x + y)
  }

  getPagesAfterActivePage() {
    return Array(Math.min(this.props.maxPageButtonsToShow, Math.max(0, this.props.maxPage-this.props.activePage))).fill(this.props.activePage+1).map((x, y) => x + y)
  }

  render(){
    return (
      <div style={{'display': 'flex', 'justifyContent': 'center'}}>
        {this.props.activePage>2 ?
          <Button 
            className={'btn-simple btn-sm'}
            onClick={() => {this.props.setPage(1)}}
            style={{'marginLeft': '4px'}}>
            <FontAwesomeIcon icon={solid('backward-fast')} />
          </Button>
        :''}
        {this.props.activePage>1 ?
          <Button 
            className={'btn-simple btn-sm'}
            onClick={() => {this.props.setPage(this.props.activePage-1)}}
            style={{'marginLeft': '4px'}}>
            <FontAwesomeIcon icon={solid('backward-step')} />
          </Button>
        :''}
        {this.getPagesBeforeActivePage().map((page) => (
          <Button 
            className={'btn-simple btn-sm'}
            onClick={() => {this.props.setPage(page)}}
            style={{'marginLeft': '4px'}}>
            {page}
          </Button>
        ))}
        <Button 
          className={'btn-simple btn-sm active'}
          onClick={() => {this.props.setPage(this.props.activePage)}}
          style={{'marginLeft': '4px'}}>
          {this.props.activePage}
        </Button>
        {this.getPagesAfterActivePage().map((page) => (
          <Button
            key={page}
            className={'btn-simple btn-sm'}
            onClick={() => {this.props.setPage(page)}}
            style={{'marginLeft': '4px'}}>
            {page}
          </Button>
        ))}
        {this.props.activePage<this.props.maxPage ?
          <Button 
            className={'btn-simple btn-sm'}
            onClick={() => {this.props.setPage(this.props.activePage+1)}}
            style={{'marginLeft': '4px'}}>
            <FontAwesomeIcon icon={solid('forward-step')} />
          </Button>
        :''}
        {this.props.activePage<this.props.maxPage-1 ?
          <Button 
            className={'btn-simple btn-sm'}
            onClick={() => {this.props.setPage(this.props.maxPage)}}
            style={{'marginLeft': '4px'}}>
            <FontAwesomeIcon icon={solid('forward-fast')} />
          </Button>
        :''}
      </div>
    );
  }
}
