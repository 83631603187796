import React  from 'react';

export default class Root extends React.Component {

  constructor(props) {
    super(props)
    this.props.i18n.changeLanguage(this.getLanguage());
  }


  componentDidMount() {
    document.querySelector("html").lang = this.getLanguage();
  }


  getLanguage() {
    //Expected to be run in a component with withRouter attached
    if(!this.props.router) {return 'en';}
    switch(this.props.router.params.lang) {
      case 'en':
      case 'fr':
      case 'de':
        return this.props.router.params.lang;
      default:
        return 'en';
    }
  }


  changeLanguage(targetLang) {
    const thisPath = this.props.router.location.pathname;
    this.props.router.navigate(
      thisPath.endsWith('/'+this.getLanguage()) ?
       thisPath.replace('/'+this.getLanguage(),'/'+targetLang) 
      : 
      thisPath.replace('/'+this.getLanguage()+'/','/'+targetLang+'/')
    );
    document.querySelector("html").lang = targetLang;
    this.props.i18n.changeLanguage(targetLang);
  }

}