import React  from 'react';
import Root from './root'
import { withRouter } from '../../components/hocs';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

export default withRouter(withTranslation('header')(class Footer extends Root {

  render() {
    const { language } = this.getLanguage()
    return (
      <footer>
        <div className="ps_footer_menu">
          <Link to={`/${language}`}>{this.props.t('Home')}</Link>
          {` | `}
          <Link to={`/${language}/c/digitalsignatures`}>{this.props.t('Digital Signatures')}</Link>
          {` | `}
          {/*<a href="http://validator.w3.org/check?uri=https://www.paulstaubin.ca/en/c/about">xHtml</a>
          {` | `}
          <a href="http://jigsaw.w3.org/css-validator/validator?uri=https://www.paulstaubin.ca/en/c/about">CSS</a>
          {` | `}*/}
          {language==='en' ?
              <span className="fake_link" onClick={() => this.changeLanguage('fr')}>Français</span>
            :
              <span className="fake_link" onClick={() => this.changeLanguage('en')}>English</span>
          }
        </div>
        (c) paulstaubin.ca 2010-{new Date().getFullYear()}, {new this.props.PSCMS().name} {new this.props.PSCMS().softwareVersion}
      </footer>
    );
  }
}))
