import React from 'react';
import Error from '../error/index';
import { MarkdownCompatibleComponent, ContentPlaceholder, ContentHeader, withRouter } from 'psCMS-common';
import Markdown from 'markdown-to-jsx'

export default withRouter(class ContentLayout extends MarkdownCompatibleComponent {


  findCorrespondingContent() {
    for( var cIx in this.props.content) {
      if(this.props.content[cIx].key===this.props.router.params.key && this.props.content[cIx].language===this.props.router.params.lang ) {
        return this.props.content[cIx]
      }
    }
    return null
  }


  render(){
    const content = this.findCorrespondingContent();
    return (
      !this.props.content ?
        <>
          <ContentPlaceholder style={{'marginBottom': '40px', 'height': '130px'}}/>
          <ContentPlaceholder style={{'marginLeft': '50px', 'width': 'initial'}}/>
          <ContentPlaceholder />
          <ContentPlaceholder />
          <ContentPlaceholder />
          <ContentPlaceholder style={{'width': '35%'}}/>
        </>
          
      :
        !content ?
          <Error status={404} />
        :
          <>
            <ContentHeader
              dateTime={new Date(content.updatedAt)}
              title={content.superTitle}
              subtitle={content.title}
            />
            <div className="reading">
              {content.content ?
                <Markdown options={this.getMarkdownOptions()}>{content.content}</Markdown>
              :
                ''
              }
            </div>
          </>
    )
  }

})
