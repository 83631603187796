import React  from 'react';
import Root from './root'
import { withRouter } from '../../components/hocs';
import ThemeToggle from "./themeToggle";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { withTranslation } from 'react-i18next';

export default withRouter(withTranslation('header')(class Header extends Root {

  constructor(props) {
    super(props)
    this.state = {
      searchExpanded: false,
      offcanvasIsOpen: false
    }
  }


  useTransition(targetGroup) {
    if(this.props.localGroup) {
      if(this.props.localGroup===targetGroup) {
        return false
      }else{
        return true
      }
    }else{
      return false
    }
  }


  render(){
    const language = this.getLanguage();

    return (
      <div className={this.props.variety==='landing' ? 'landing_heading' : 'blank_heading'}>
        <div className={this.props.variety==='landing' ? '' : 'blank_heading_wrapper'} style={this.props.variety==='landing' ? {} : {animation: '0.4s ease-out 0s 1 normal none running slideUp'}}>
          <TransitionNavLink to={`/${language}`} id="ps_title" className={"ps_title"+(this.props.variety==='landing'? '' : ' ps_title_can_hover')} useTransition={this.useTransition('public')} useLink={true}>
            <svg width="208" height="163" xmlns="http://www.w3.org/2000/svg">
              <title>paulstaubin.ca logo</title>
              <g id="Arrow_components">
                <path fill="#31a1ff" id="Arrow1_main_body" d="m131.61712,20.02774l19.70214,-18.9595l56.32171,53.54541l-56.23269,53.65097l-19.58801,-18.98622l21.62636,-21.36778l-39.08704,0l0,-26.28991l39.33469,-0.0929"></path>
                <path fill="#31a1ff" id="Arrow1_rear_body" d="m16.80417,41.52782l86.52834,0l0,26.3828l-102.67993,0l16.15159,-26.3828z"></path>
                <path fill="#656565" id="Arrow2_main_body" d="m57.65705,84.48751l33.97403,32.46897l-0.11984,-30.61282l28.0758,0.0165l0.089,75.56452l-80.02209,-0.095l0.38775,-26.42442l31.71626,-0.095l-33.44704,-32.11368l19.34613,-18.70907z"></path>
                <path fill="#656565" id="Arrow2_rear_body" d="m31.90599,96.97796l-31.25341,-29.06734l39.6368,-0.15395l10.96269,10.51236l-19.34608,18.70893z"></path>
              </g>
            </svg>
            <span className="ps_title_major">Paul<span style={{display: 'none'}}>&nbsp;</span>St<span className="ps_title_highlight">-</span>Aubin.ca</span>
            <span className="ps_title_minor">{this.props.t('Transportation Engineer')}</span>
            <span className="ps_title_hover_text">{this.props.t('Return to')}</span>
            <span className="ps_title_hover_text">{this.props.t('Home Page')}</span>
          </TransitionNavLink>


          <nav>
            <div className={"heading_nav_full"+(this.state.offcanvasIsOpen ? ' open' : '')}>
              <span className="btn close heading_nav_mobile_close" onClick={() => {this.setState({offcanvasIsOpen:false})}}>&times;</span>
              <div className="nav-item">
                <span>{this.props.t('Info')}</span>
                <TransitionNavLink to={`/${language}/c/about`} useTransition={this.useTransition('public')}>{this.props.t('About')}</TransitionNavLink>
                <ul className="ps-dropdown-menu">
                  <li><TransitionNavLink to={"/"+language+"/contact"} useTransition={this.useTransition('public')} className="dropdown-item"> {this.props.t('Contact')}</TransitionNavLink></li>
                </ul>
              </div>

              <div className="nav-item">
                <span>{this.props.t('Work')}</span>
                <TransitionNavLink to={`/${language}/publications`} useTransition={this.useTransition('public')}> {this.props.t('Publications')}</TransitionNavLink>
                <ul className="ps-dropdown-menu">
                  <li><TransitionNavLink to={`/${language}/c/software`} useTransition={this.useTransition('public')} className="dropdown-item">{this.props.t('Software')}</TransitionNavLink></li>
                  <li><TransitionNavLink to={`/${language}/c/teaching`} useTransition={this.useTransition('public')} className="dropdown-item">{this.props.t('Teaching')}</TransitionNavLink></li>
                  {/*<li><TransitionNavLink to={`/${language}/c/maps`} useTransition={this.useTransition('public')} className="dropdown-item">{this.props.t('Maps & Data')}</TransitionNavLink></li>*/}
                </ul>
              </div>

            
              {this.props.loggedIn ?
                <div className="nav-item">
                  <span>{this.props.t('Internal')}</span>
                  <TransitionNavLink to={`/admin/${language}/home`} rel="nofollow" useTransition={this.useTransition('admin')}>{this.props.t('Admin')}</TransitionNavLink>
                  <ul className="ps-dropdown-menu">
                    <li><a name='logout' onClick={() => {if(this.props.logout) {this.props.logout()}}} className="dropdown-item">{this.props.t('Logout')}</a></li>
                  </ul>
                </div>
              :
                <div className="nav-item">
                  <span>{this.props.t('Internal')}</span>
                  <TransitionNavLink to={`/${language}/login`} rel="nofollow" useTransition={this.useTransition('public')}>{this.props.t('Login')}</TransitionNavLink>
                </div>
              }

              <div className="nav-item">
                <span>{this.props.t('External')}</span>
                <TransitionNavLink to={`/${language}/links`} useTransition={this.useTransition('public')}>{this.props.t('Links')}</TransitionNavLink>
                <ul className="ps-dropdown-menu">
                  <li className="icon-authorea"><a href="https://www.authorea.com/users/6570" className="dropdown-item">Authorea</a></li>
                  <li className="icon-bitbucket"><a href="https://bitbucket.org/pstaub" className="dropdown-item">BitBucket</a></li>
                  <li className="icon-codepen"><a href="https://codepen.io/pstaubs" className="dropdown-item">Codepen</a></li>
                  <li className="icon-codestats"><a href="https://codestats.net/users/pstaubs" className="dropdown-item">Code::Stats</a></li>
                  <li className="icon-github"><a href="https://github.com/pstaubs" className="dropdown-item">Github</a></li>
                  <li className="icon-linkedin"><a href="http://ca.linkedin.com/in/paulstaubin" className="dropdown-item">LinkedIn</a></li>
                  <li className="icon-thingiverse"><a href="https://www.thingiverse.com/pstaub" className="dropdown-item">Thingiverse</a></li>
                </ul>
              </div>


              <div className="nav-tools">
                <span>{this.props.t('Tools')}</span>
                <div>
                  <div className="nav-tools-activatable">
                    <FontAwesomeIcon icon={solid('language')} />
                    <ul className="ps-dropdown-menu">
                      <li onClick={() => this.changeLanguage('en')}><a name="en" className={'dropdown-item ' + (language==='en' ? 'active' : '')}>English</a></li>
                      <li onClick={() => this.changeLanguage('fr')}><a name="fr" className={'dropdown-item ' + (language==='fr' ? 'active' : '')}>Français</a></li>
                      <li onClick={() => this.changeLanguage('de')}><a name="de" className={'dropdown-item ' + (language==='de' ? 'active' : '')}>Deutsch</a></li>
                    </ul>
                  </div>
                  <div>
                    <ThemeToggle />
                  </div>
                  <div>
                    <form method="GET" action="https://www.google.com/search" id="ps-search-form" onBlur={() => {this.setState({searchExpanded: false});}}>
                      <input type="hidden" name="sitesearch" value="paulstaubin.ca" />
                      <input 
                        type="search"
                        id='search'
                        name="q"
                        className={"ps-search"}
                        placeholder={this.props.t('Search domain', {domain_name: 'paulstaubin.ca'})}
                      />
                      <FontAwesomeIcon 
                        className={"ps-search-icon"+(this.state.searchExpanded===true ? ' ps-search-icon-expanded' : '')}
                        icon={solid('magnifying-glass')} onClick={() => {
                          if(this.state.searchExpanded!==true) {
                            this.setState({searchExpanded: true});
                            document.getElementById('search').focus();
                          }
                        }}
                        onMouseDown={() => {
                          if(this.state.searchExpanded===true) {
                            document.getElementById('ps-search-form').submit();
                          }
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="heading_nav_mobile_link">
              <div className="nav-item">
                <span>{this.props.t('Site')}</span>
                <a className="" onClick={() => {this.setState({offcanvasIsOpen:!this.state.offcanvasIsOpen})}}><span>≡</span>&nbsp; {this.props.t('Navigation')}</a>
              </div>
            </div>
          </nav>

        </div>
      </div>
    );
  }
}))



class TransitionNavLink extends React.Component {
  render(){
    const {useLink, useTransition, ...props} = this.props;

    if(useTransition) {
      return (
        <a href={this.props.to} {...props} />
      );
    }else if(useLink) {
      return (
        <Link {...props} />
      );
    }else{
      return (
        <NavLink {...props} />
      );
    }
  }
}
