import React  from 'react';
import API from '../../components/api.js'
import InlineIsLoadingIcon from '../basics/inlineIsLoadingIcon.js'
import { WithContext as ReactTags } from 'react-tag-input';


export default class DynamicFormField extends API {
  static defaultProps = {
    suggestions: [] // For ReactTags Primarily
  };


  constructor(props) {
    super(props);
    value = (props.value===undefined || props.value===null ? (props.type=='tags' ? [] : '') : props.value)
    this.state = {
      ...this.getCommonState(props),
      value: value,
      isValid: true
    }
    this.lastValue = value
    this.inactivityTimoutId = null;
  }


  componentDidUpdate(prevProps) {
    //manually update the state if the props it is derived from is updated; react won't do this on it's own (and I guess that makes sense)
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value})
    }
  }


  updateField(e) {
    if(this.lastValue==e.target.value || !this.props.apiEndPoint) {
      this.setState({isLoading:null});
      return false
    }else{
      if(!e.target.checkValidity()) {
        this.setState({isLoading:'invalid'});
        return false
      }else{}
    }
    this.setState({value: e.target.value}, this.update)
    window.clearTimeout(this.inactivityTimoutId);
  }


  update() {
    if(this.props.apiEndPoint) {
      this.api(
        this.props.apiEndPoint,
        'PUT', {
        successCallback:(data) => {
          this.lastValue = this.state.value
        },
        body: {
          [this.props.apiKey]: this.state.value
        }}
      )
    }
    this.props.changeCallback(this.state.value)
  }


  checkForInactivity(e) {
    this.setState({value: e.target.value})
    this.lastUpdate = Date.now();
    window.clearTimeout(this.inactivityTimoutId);
    this.inactivityTimoutId = setTimeout(() => {
      if (Date.now() - this.lastUpdate > 500) {
        this.updateField(e);
      }
    }, 1500);
  };


  renderInput() {
    const { apiKey, apiEndPoint, children, changeCallback, ...props } = this.props;

    switch (this.props.type) {
      case 'textarea':
        return (
          <textarea
            {...props}
            className="form-control"
            onChange={this.checkForInactivity.bind(this)}
            placeholder={this.props.placeholder}
            value={this.state.value===null ? '' : this.state.value}
            onBlur={this.updateField.bind(this)}
          />
        )
      case 'select':
        return (
          <select
            {...props}
            className="form-select"
            value={this.state.value}
            onChange={this.updateField.bind(this)}>
            {children}
          </select>
        )
      case 'tags':

        const KeyCodes = {
          comma: 188,
          enter: 13,
          space: 32,
          tab: 9
        };

        return (
          <ReactTags
            tags         = {this.state.value.map((val)=>{val.id = val.id.toString(); return val})} // Enforce ids as strings
            suggestions  = {this.props.suggestions.map((val)=>{return {id: val.id.toString(), text: val.text}})} // Enforce ids as strings
            delimiters   = {[KeyCodes.comma, KeyCodes.enter, KeyCodes.tab]}
            handleDelete = {(i) => {
              this.setState(
                {value: this.state.value.filter((tag, index) => index !== i)},
                this.update
                );
            }}
            handleAddition={(tag) => {
              this.setState(
                {value: [...this.state.value, tag]},
                this.update
                );
            }}
            handleDrag={(tag, currPos, newPos) => {
              const newTags = this.state.value.slice();
              newTags.splice(currPos, 1);
              newTags.splice(newPos, 0, tag);
              this.setState({value: newTags});
            }}
            handleTagClick={(index) => {
              if(this.props.onClickCallback) {this.props.onClickCallback(this.state.value[index]);}
              //console.log('The tag at index ' + index + ' was clicked');
            }}
            inputFieldPosition="bottom"
            placeholder = {this.props.placeholder}
            autocomplete
            classNames={{
              tags: 'tags-container',
              tagInput: 'tags-input',
              tagInputField: 'form-control',
              selected: 'tags-selected',
              tag: 'tag',
              remove: 'btn-close',
              suggestions: 'tags-suggestions',
              activeSuggestion: 'activeSuggestionClass',
              editTagInput: 'editTagInputClass',
              editTagInputField: 'editTagInputField',
              clearAll: 'clearAllClass',
            }}
          />
        )
      case 'url':
      case 'email':
        return (
          <input 
            {...props}
            type={this.props.type}
            className="form-control"
            value={this.state.value}
            onBlur={this.updateField.bind(this)}
            onChange={this.checkForInactivity.bind(this)}
          />
        )
      default:
        return (
          <input 
            {...props}
            type="text"
            className="form-control"
            value={this.state.value}
            onBlur={this.updateField.bind(this)}
            onChange={this.checkForInactivity.bind(this)}
          />
        )
    }
  }


  render(){
    return (
      <div style={{'position':'relative'}}>
        {this.renderInput()}
        <InlineIsLoadingIcon 
          actionWord="Saving"
          style={{'position':'absolute', 'right':'10px', 'top':'50%', 'transform':'translateY(-50%)' }}
          isLoading={this.state.isLoading}
          status={this.state.status} />
      </div>
    )
  }
}
