import React  from 'react';
import Header from './header';
import Footer from  './footer';
import Notification from '../basics/notification.js'
import Basic from '../../components/basic'
import { withRouter } from '../../components/hocs';


export default withRouter(class BaseLayout extends Basic {

  constructor(props) {
    super(props)
    this.state = {
      notifications: []
    }
    window.addNotification = this.addNotification.bind(this)
  }


  addNotification(type, msg, link, link_text) {
    this.setState({notifications: [...this.state.notifications, {type: type, msg: msg, link:link, link_text:link_text}]});
  }


  componentDidUpdate(prevProps) {
    //Cleanup notifications if route changes
    if (prevProps.router.location !== this.props.router.location) {
      this.setState({notifications: []});
    }
  }


  render(){
    return (
      <React.Fragment>
        <Header {...this.props} />
        <main className={this.props.customClass!=undefined ? this.props.customClass : ''}>
          <div className="blank_content">
            <div id="notification-area" className="notification-area">
              {this.state.notifications.map(notification => (
                <Notification
                  key={this.generateUID()}
                  {...notification}>
                  {notification.msg}
                </Notification>
              ))}
            </div>
            {this.props.children}
          </div>
        </main>
        <Footer {...this.props} />
      </React.Fragment>
    );
  }
})
