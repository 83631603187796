import React  from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { API, ContentHeader, ContentPlaceholder } from 'psCMS-common';

export default withTranslation('links')(class Links extends API {

  
  getCommonState(props) {
    return {
      ...super.getCommonState(props),
      searchText: '',
      linksByTag: {},
      activeTag: null,
      activeChildTag: null,
    }
  }


  componentDidMount() {
    if(!this.state.tags) {
      this.api('/tags/list', 'GET', {onSuccess:(data) => {this.setState(data)}});
    }
  }


  handleTagSearch(e) {
    this.setState(
      {
        searchText:e.target.value,
        activeTag: null
      },
      () => {
        let tags = this.getFilteredTags();
        if(tags.length===1 && this.state.activeTag!==tags[0].id) {
          this.setState(
            {activeTag: tags[0].id},
            this.queryLinksForActiveTag
          )
        }
      }
    );
  }



  queryLinksForActiveTag(childTagMode=false) {
    const activeTag = childTagMode ? this.state.activeChildTag : this.state.activeTag;
    if(!this.state.linksByTag[activeTag]) {
      this.api('/links/list', 'POST', {onSuccess:(data) => {this.setState({linksByTag: {...this.state.linksByTag, [data.tag]:data.links}})}, body:{tag: activeTag}});
    }
  }


  getFilteredTags() {
    if(this.state.searchText.length >= 3) {
      return this.state.tags.filter((tag) => {return tag.text.toLowerCase().match(this.state.searchText.toLowerCase())});
    }else{
      return this.state.tags;
    }
  }


  renderTag(tag, onClick=null, childTagMode=false, customContent='') {
    const activeTag = childTagMode ? this.state.activeChildTag : this.state.activeTag;
    return <span key={tag.id} className={"tag"+(activeTag===tag.id ? ' active' : '')} onClick={onClick ? onClick : ()=>{this.setState({[childTagMode ? 'activeChildTag' : 'activeTag']: +tag.id}, ()=>{this.queryLinksForActiveTag(childTagMode)})}}>
      {this.state.searchText.length >= 1 ?
        tag.text.split(tag.text.match(new RegExp(this.state.searchText, 'i'))).flatMap((i)=>[i,<mark key={this.generateUID()}>{tag.text.match(new RegExp(this.state.searchText, 'i'))}</mark>]).slice(0,-1)
      :
        tag.text
      }
      {customContent ? ' ' : ''}{customContent}
    </span>
  }


  render() {
    const activeTag = this.state.activeChildTag || this.state.activeTag
    if(!this.state.tags) {
      return (
        <>
          <ContentPlaceholder style={{'marginBottom': '40px', 'height': '130px'}}/>
          <ContentPlaceholder style={{'marginLeft': '50px', 'width': 'initial'}}/>
          <ContentPlaceholder />
          <ContentPlaceholder />
          <ContentPlaceholder />
          <ContentPlaceholder style={{'width': '35%'}}/>
        </>
      )
    }else{
      let filteredTags = this.getFilteredTags();
      return (
        <>
          <ContentHeader
            title={this.props.t('title')}
            subtitle={this.props.t('subtitle')}
          >
            <span className="links-search-container">
              <input 
                type="search"
                id='search'
                className={"ps-search ps-search-static"}
                placeholder={this.props.t('Search link categories...')}
                value={this.state.searchText}
                onChange={this.handleTagSearch.bind(this)}
              />
              <FontAwesomeIcon className={"ps-search-icon ps-search-icon-expanded inverted"} icon={solid('magnifying-glass')}/>
            </span>
          </ContentHeader>

          <div className="tag-container ps_pop">
            {this.state.searchText.length <= 3 ?
              this.state.activeTag ?
                <>
                  {this.renderTag(this.state.tags.find((tag) => tag.id===this.state.activeTag), () => {this.setState({activeTag: null, activeChildTag: null})}, false, <FontAwesomeIcon icon={solid("circle-xmark")}/>)}
                  <FontAwesomeIcon icon={solid("ellipsis")} style={{marginRight: '20px'}} onClick={() => {this.setState({activeTag: null, activeChildTag: null})}}/>
                  {this.state.tags.filter((childTag) => childTag.parent===this.state.activeTag).map((childTag, tIx) => {
                    return this.renderTag(childTag, null, true);
                  })}
                </>
              :
                this.state.tags.filter((tag) => tag.parent===null).map((tag, tIx) => {
                  return this.renderTag(tag);
                })
            : filteredTags.length === 0 ?
              <div>🤔 {this.props.t("Hmm, couldn't find any tags that match your search.")}</div>
            :
              filteredTags.map((tag, tIx) => {
                return this.renderTag(tag);
              })
            }
          </div>
          <div className="links-container ps_pop">
            {activeTag == null ?
              <div>{this.props.t("Select a category to get started.")}</div>
            : this.state.isLoading || Object.keys(this.state.linksByTag).length === 0 ?
              <>
                <ContentPlaceholder />
                <ContentPlaceholder />
                <ContentPlaceholder style={{'width': '35%'}}/>
              </>
            :!this.state.linksByTag[activeTag] || this.state.linksByTag[activeTag].length === 0 ?
                <div>🤔 {this.props.t("Hmm, there doesn't seem to be any links for the tag:")} <span className={"tag"}>{this.state.tags.find(tag => tag.id === activeTag).text}</span></div>
            :
              this.state.linksByTag[activeTag].map((link, lIx) => (
                <a key={link.id} href={link.url} className="links-item">
                  <div className="links-item-image" style={{backgroundImage: 'url("/public/images/link-logos/'+link.id+'.png")'}}>
                    <div className="links-item-description">{link.description}</div>
                    <div className="links-item-tags">{link.tags.map((tag) => {
                      return <span className="tag" key={tag.id} onClick={(e)=>{e.preventDefault();this.setState({activeTag: tag.id}, ()=>{this.queryLinksForActiveTag()})}}>
                        {tag.text}
                      </span>
                    })}</div>
                  </div>
                  <div className="links-item-image-dark-overlay"></div>
                  <div className="links-item-margin">
                    <div className="links-item-status">
                      {link.status>=400 ? 
                        <FontAwesomeIcon icon={solid("link-slash")} className="centered" /> :
                      link.status>=300 ? 
                        <FontAwesomeIcon icon={solid("diamond-turn-right")} className="centered" /> :
                      link.status>=200 ?
                        <FontAwesomeIcon icon={solid("link")} className="centered" /> 
                      :
                        <FontAwesomeIcon icon={solid("question")} className="centered" /> 
                      }
                    </div>
                    <div>
                      {/^https:\/\//.test(link.url) ?
                        <FontAwesomeIcon icon={solid("lock")} className="centered" />
                      :
                        <FontAwesomeIcon icon={solid("lock-open")} className="centered" />
                      }
                    </div>
                    <div>
                      {!link.language ? 
                        ''
                      :
                        <span className="links-item-language-box centered">{link.language}</span>
                      }
                    </div>
                  </div>
                  <div className="links-item-title">
                    <div className="links-item-title-stacker">
                      <div className="links-item-domain">
                        {link.url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/)[1]}
                      </div>
                      <div className="links-item-page-title">
                        {link.title}
                      </div>
                    </div>

                  </div>
                
                </a>
              ))
            }
          </div>
        </>
      )
    }
  }
})