import React  from 'react';
import Basic from './basic';

export default class API extends Basic {
  

  constructor(props) {
    super(props);
    this.state = {
      ...this.getCommonState(props),
    }
  }


  getCommonState(props) {
    //Placeholder. Expand this object to add state variables to be initiated
    return {
      isLoading: null,
      status: null,
    }
  }

  getAPIStatusProps() {
    return { isLoading: this.state.isLoading, status: this.state.status }
  }

  
  api(apiURL, method, {onSuccess=(data)=>{}, onError=()=>{}, body=null, silently=false}) {
    if(!silently) {this.setState({isLoading: true})}
    fetch(apiURL, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      ...(body!=null ? {body:JSON.stringify(body)} : {})
    })
    .then(res => {
      // Unfortunately, fetch doesn't send (404 error) into the cache itself
      this.setState({status: res.status})
      if(res.status >= 400) {
        console.log(res)
        throw new Error(`Server responded with error ${res.status}!`);
      }
      return res.json()
    })
    .then(data => {
      onSuccess(data);
      if(!silently) {this.setState({isLoading: false})}
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components
    err => {
      onError();
      console.log(err);
      if(!silently) {this.setState({isLoading: 'error'})}
    });
  }


}
