import React  from 'react';
import { ContentHeader, ContentPlaceholder, API } from 'psCMS-common';
import { Trans, withTranslation } from 'react-i18next';
import {parseBibFile, normalizeFieldValue} from "bibtex";

export default withTranslation('publications')(class Publications extends API {

  static defaultProps = {
    highlightAuthor:'ST-AUBIN'
  };



  componentDidMount() {
    if(!this.state.contents) {
      this.api('/publications/list', 'POST', {onSuccess:(data) => {this.setState(data)}});
    }
  }

  types() {
    return {
      1: 'Journals',
      2: 'Peer-Reviewed Conference Proceedings',
      3: 'Theses',
      4: 'Technical Reports',
      5: 'Technical Guides',
      6: 'Presentations',
      7: 'Webinars',
      8: 'Professional Publications'
    }
  }

  render() {
    return (
      <>
        <ContentHeader
          dateTime={new Date()}
          title={this.props.t('title')}
          subtitle={this.props.t('subtitle')}
        />
        <div className="reading">
          <p className="ps_pop"><Trans t={this.props.t}>Bibtex citations and manuscript copies are available for my publications. Check the <i>Details</i> section under each listing for these and more information about each publication.</Trans></p>
        </div>

        {!this.state.publications ?
          <>
            <ContentPlaceholder />
            <ContentPlaceholder />
            <ContentPlaceholder />
            <ContentPlaceholder style={{'width': '35%'}}/>
          </>
        :
          Object.entries(this.types()).map((type) => {
            var publications = this.state.publications.filter(x => x.type===parseInt(type[0]));
            if(publications.length === 0) {return '';}
            else{
              return (
                <div className="reading ps_pop" key={type[0]}>
                  <h1 className="ps_pop" id="journals" style={{marginTop: '20px'}}>{this.props.t(type[1])}</h1>
                  <ul className="ps_pop">
                    {publications.map((publication, pIx) => {
                      let bibtex = parseBibFile(publication.bibtex).entries_raw[0];
                      let authors = normalizeFieldValue(bibtex.fields.author).replace(this.props.highlightAuthor,'<b>'+this.props.highlightAuthor+'</b>')
                      return (
                      <li id={bibtex._id} key={'publication'+pIx}>
                        {authors} ({normalizeFieldValue(bibtex.fields.year)}) <a href={publication.urlPub} target="_blank" rel="noreferrer"><i>{normalizeFieldValue(bibtex.fields.title)}</i></a>, {normalizeFieldValue(bibtex.fields.publisher)}, vol. {normalizeFieldValue(bibtex.fields.volume)}, pp. {normalizeFieldValue(bibtex.fields.pages)}
                        <details className="pub_listing pub_listing_small">
                          <summary><span className="pub_listing_details">Details</span></summary>
                          <span className="ps_tree_listing fake_link" onClick={() => {document.getElementById("bibtex_for_"+bibtex._id).classList.toggle('slideOpen')}}>Get bibtex reference</span>
                          <textarea id={"bibtex_for_"+bibtex._id} className="textarea_plain_text bibtex slideOpen_default" cols="100" rows="10" defaultValue={publication.bibtex} />
                        </details>
                      </li>)
                    })}
                  </ul>
                </div>
              )
            }
          })
        }
        
      </>
    );
  }
})
