import React  from 'react';
import { Basic } from 'psCMS-common';


export default class CometGenerator extends Basic {

  constructor(props) {
    super(props)
    this.state = {
      comets: []
    }
  }


  normal() {return ((Math.random() + Math.random() + Math.random() + Math.random() + Math.random() + Math.random()) - 3) / 3;}


  updateCometList() {
    //Prepare changes
    var comets = this.state.comets;
    comets = comets.map((comet, cIx) => {
      //comet.x += comet.speed*0.2;
      comet.time += 1;
      return comet;
    })
    .filter((comet) => comet.time < 50);

    if(comets.length < 50) {
      comets.push({
        id: this.generateUID(),
        size: Math.random(),
        speed: Math.random(),
        x: Math.random()*30,
        y: Math.random()*600,
        time: 0
      });
    }


    //Commit changes and queue next random modification
    this.setState(
      {comets: comets},
      () => {setTimeout(() => {this.updateCometList()}, (this.normal()+0.5)*400)}
    );
  }


  componentDidMount() {
    //Start generator/updater when component is mounted
    this.updateCometList();
  }


  render(){
    return (
      <div className="landing_bg_1" id="comets">
        {this.state.comets.map((comet, cIx) => (
          <div
            id={comet.id}
            key={comet.id}
            className={"comet comet-"+(comet.size  < 0.4 ? "sm" : (comet.size  < 0.4 ? "md" : "lg"))+" comet-sail-"+(comet.speed  < 0.3 ? "slow" : (comet.speed  < 0.7 ? "med" : "fast"))}
            style={{"top":comet.y+"px", "left":comet.x+"%"}}
          />
        ))}
      </div>
    )
  }
}
