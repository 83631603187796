import React from 'react';
import Sortable from './concerns/sortable';
import Modal from '../views/basics/modal';
import FileIO from './fileIo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Button from 'react-bootstrap/Button';




class FileBrowser extends FileIO {
	apiURL = '/upload/list';
	method = 'POST';
	deleteFwdReference = React.createRef();

	getCommonState(props) {
		return {
			...super.getCommonState(props),
			sortBy: null,
			sortDescending: false,
			activeFile: null,
		}
	}


	componentDidMount() {
		this.get();
	}


	getSuccessCallback(data) {
		this.setState({files: data.files.reduce((a, v) => ({ ...a, [v.id]: v}), {})})
	}


	getListBody() {
		return {path: this.state.cwd}
	}


	get() {
		this.api(this.apiURL, this.method, {onSuccess:this.getSuccessCallback.bind(this), body: this.getListBody()})
	}


	renderFileBrowser() {
		return (
			<div className="fileManager">
				<div className="fileManager-sidebar">
					<ul className="fileManager-tree">
						<li className="fileManager-tree-item"><FontAwesomeIcon icon={solid('folder')} /> Project A</li>
						<li className="fileManager-tree-item fileManager-tree-item--open">
							<FontAwesomeIcon icon={solid('folder-open')} /> Project B
							<ul className="fileManager-tree">
								<li className="fileManager-tree-item"><FontAwesomeIcon icon={solid('folder')} /> Planfolder</li>
								<li className="fileManager-tree-item"><FontAwesomeIcon icon={solid('folder')} /> Holiday</li>
								<li className="fileManager-tree-item"><FontAwesomeIcon icon={solid('folder')} /> Shared</li>
							</ul>
						</li>
					</ul>
				</div>
				<div className="fileManager-list">
					<div className="fileManager-list-header">
						<ul className="fileManager-list-header-path">
							{this.state.cwd.split('/').map(folder =>
								<li key={folder}>{folder}</li>
							)}
						</ul>
						<span className="fileManager-list-header-btn options-toggle"> <FontAwesomeIcon icon={solid('gear')} /></span>
						<span className="fileManager-list-header-btn help-btn" data-overlay="help"> <FontAwesomeIcon icon={solid('circle-question')} /></span>
					</div>
					<div className="fileManager-list-table-container">
						<table className="table table-striped fileManager-list-table">
							<thead>
								<tr className="file-list__header">
									<th>{this.renderSortingControls('type')}</th>
									<th>{this.renderSortingControls('name', 'Name')}</th>
									<th>{this.renderSortingControls('size', 'Size')}</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{Object.values(this.state.files).map(file =>
									<tr key={file.id}>
										<td>{this.guessFileIcon(file)}</td>
										<td><a href={file.url} download>{file.name}</a></td>
										<td style={{textAlign: 'right'}}>{this.humanizeFileSize(file.size)}</td>
										<td style={{textAlign: 'center'}}>
											<FontAwesomeIcon 
												icon={solid('trash')}
												onClick={()=>{this.setState({activeFile: file.id}, ()=>{this.deleteFwdReference.current.setState({showModal: true})})}}
												style={{cursor: 'pointer'}}
											/>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>

				<Modal
					ref={this.deleteFwdReference}
					title="Delete File"
					action={
						<Button
							variant="danger"
							onClick={() => {this.deleteFile(this.state.activeFile); this.deleteFwdReference.current.setState({showModal: false});}}>
							<FontAwesomeIcon icon={solid('trash')} /> Delete File
						</Button>
					}>
					<p>
						Are you sure you would like to delete the file "{this.state.activeFile && this.state.files[this.state.activeFile] ? this.state.files[this.state.activeFile].name : ''}"? This action is permanent.
					</p>
				</Modal>
			</div>
		)
	}

}

Object.assign(FileBrowser.prototype, Sortable);
export default FileBrowser