import React  from 'react';
import { withTranslation } from 'react-i18next';
import { LineFormField } from 'psCMS-common';

export default withTranslation('common')(class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isLoading: false
    }
  }


  handleUsernameChange(e) {this.setState({username: e.target.value})};
  handlePasswordChange(e) {this.setState({password: e.target.value})};


  handleSubmit(e) {
    e.preventDefault();
    this.setState({isLoading: true})
    fetch('/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    })
    .then(res => {
      // Unfortunately, fetch doesn't send (404 error) into the cache itself
      if(res.status === 401) {
        window.addNotification('error', 'Login information refused.')
        throw new Error(401);
      }else if(res.status >= 400) {
        console.log(res)
        throw new Error(`Server responded with error ${res.status}!`);
      }
      return res.json();
    })
    .then(data => {
      window.location.href = data.location
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components
    err => {
      console.log(err)
      this.setState({
        isLoading: false
      })
    });
  }


  render(){
    return (
      <>
        <form className="ps_lineForm ps_pop reduced" onSubmit={this.handleSubmit.bind(this)} encType="multipart/form-data">
        <p className="ps_lineForm_header ps_pop reduced">{this.props.t('Login')}</p>
          <LineFormField
            id={"username"}
            name={"username"}
            value={this.state.username}
            size={"reduced"}
            required={true}
            onChange={this.handleUsernameChange.bind(this)}
            description={this.props.t('Username or e-mail')}
          />
          <LineFormField
            id={"password"}
            name={"password"}
            type={"password"}
            value={this.state.password}
            size={"reduced"}
            required={true}
            onChange={this.handlePasswordChange.bind(this)}
            description={this.props.t('Password')}
          />
          <div>
            <input type="submit" name="submit" value={this.props.t('Submit')} className="button" />
            {' '}
            <input type="button" className="button" name="recover" value={this.props.t('Recover password')} style={{maxWidth:'320px'}} formNoValidate="" />
          </div>
        </form>
      </>
    );
  }
})
