import React  from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { withTranslation } from 'react-i18next';

export default withTranslation('common')(class Notification extends React.Component {

  getNotificationIcon() {
    switch(this.props.type) {
      case 'error': return <FontAwesomeIcon className="fa-2x" icon={solid('circle-xmark')} />; break;
      case 'warning': return <FontAwesomeIcon className="fa-2x" icon={solid('triangle-exclamation')} />; break;
      case 'success': return <FontAwesomeIcon className="fa-2x" icon={solid('circle-check')} />; break;
      case 'info':
      default:
        return <FontAwesomeIcon className="fa-2x" icon={solid('circle-exclamation')} />; break;
    }
  }


  render(){
    return (
      <div className={"notification "+this.props.type+" ps_slideDown"}>{this.getNotificationIcon()}
        <div>
          <strong>{this.props.t(this.props.type)}</strong>: {this.props.children}<br />{this.props.link? <Link to={this.props.link}>{this.props.link_text}</Link> : ''}
        </div>
      </div>
    );
  }

})
