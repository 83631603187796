import React  from 'react';
import { Link } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import i18n from "i18next";

export default withTranslation('landing')(class Blurb extends React.Component {
  render(){
    return (
      <div id="landing_blurb" className="landing_blurb ps_pop">
        <svg className="decoration" width="100" height="100" viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
          <title>
            Montreal A-B
          </title>
          <path fill="none" stroke="#e5e5e5" strokeWidth="4.03" id="MontrealOutline" d="M92.5 114.44c1.9.7 4.83 1.37 7.16.8 4.3-1.06 7.74-4.45 11-7.44 2.26-2.1 4.46-4.44 5.8-7.23 1.06-2.2.55-4.94 1.6-7.15 1.08-2.3 3.96-3.55 4.68-5.98.83-2.82-.67-5.85-.8-8.8-.1-2.57-.6-5.24.03-7.75.47-1.9 1.58-3.6 2.7-5.1 1.03-1.5 2.82-2.4 3.66-3.9.85-1.7.55-3.7 1.2-5.4.7-1.9 2.68-3.3 2.9-5.3.13-1.3-1.1-2.4-1.1-3.7 0-1.8 1.27-3.38 1.5-5.15.23-1.85-.7-3.8-.2-5.6.62-2.1 2.98-3.36 3.68-5.44 1.3-3.83.1-8.14.8-12.14.2-1.17 1-2.23 1-3.4 0-1-.8-1.9-.8-2.9 0-1.1.6-2.17 1-3.2.5-1.2 1.6-2.16 1.7-3.4.1-1.1-.2-2.5-1.1-3.14-1.8-1.26-4.42-.5-6.57-.08-3.56.72-7.24 2.03-9.97 4.45-1.8 1.6-2.07 4.4-3.7 6.14-1.76 1.84-4.27 2.8-6.32 4.34-3.6 2.64-7.13 5.4-10.44 8.4-2.37 2.13-4.88 4.22-6.68 6.84-4.05 5.9-5.2 13.42-9 19.5-1.85 2.92-3.85 6-6.67 8.03-1.6 1.13-3.9.95-5.47 2.13-1.1.85-1.47 2.38-2.47 3.3-2.7 2.57-5.84 4.7-9.24 6.2-2.35 1.04-4.94 2.06-7.53 1.84-1.94-.17-3.46-2-5.4-2.33-3.75-.63-8.05-1.06-11.4.77-1.6.9-2.4 2.9-3.25 4.5-1.43 2.68-1.5 6-3.2 8.5-1.7 2.5-3.8 5.77-6.8 6.07-1.84.2-3.03-2.16-4.76-2.8-1.3-.44-2.83-1.06-4.14-.6-1.9.68-3.08 2.7-4 4.44-1 1.84-.2 4.6-1.6 6.2-1.47 1.6-4.8.62-6.1 2.4-1.5 2.1-1 5.2-.5 7.7.5 2.6 1.44 5.34 3.3 7.22 1.14 1.13 2.8 1.7 4.37 2.03 3.64.7 7.56.57 11.1-.5 1.23-.4 2.1-1.6 3.3-2.05 1.7-.6 3.7-.14 5.37-.8 2.36-.86 4-3.05 6.3-4.04 3.4-1.43 7.15-1.84 10.74-2.6 1.64-.33 3.5.04 4.9-.86 1.14-.7 1-2.8 2.27-3.3 1.8-.7 3.8.8 5.7 1.27 2.08.5 4.1 1.2 6.2 1.57 3.08.52 6.2.67 9.3.86 2.78.2 5.7-.7 8.35.25 3.15 1.13 5.63 5.34 7.9 6.17z"/>
          <path fill="none" stroke="#31a1ff" strokeWidth="3.65" id="RoutePath" d="M72.73 93.93c7.33.73 13.5 1.6 18.72.22 5.23-1.38 10.02-4.28 8.57-10.23-1.45-5.96-3.27-10.46-2.83-14.3.4-3.85 1.9-8.06 4.6-10.1 2.7-2.02 4.6-3.4 4.6-3.4l-.1.07"/>
          <path fill="#31a1ff" id="RouteArrow_Bottom" d="M61.68 90.2l17.14-.7-2.32 4.87-.07 5.8-14.75-9.98z"/>
          <path fill="#31a1ff" id="RouteArrow_Right" d="M115.6 48.84l-9.54 14.27-2.58-4.7-4.65-3.5 16.76-6z"/>
          <path fill="#31a1ff" stroke="#000" id="MarkerA" d="M73.8 61.3c0 9.25-11.18 12.16-12.12 28.9-1.4-17.2-12.12-19.65-12.12-28.9 0-6.7 5.43-12.1 12.12-12.1 6.7 0 12.1 5.4 12.1 12.1z"/>
          <path fill="#31a1ff" stroke="#000" id="MarkerB" d="M127.7 19.95c0 9.25-11.17 12.16-12.1 28.9-1.42-17.2-12.12-19.65-12.12-28.9 0-6.7 5.42-12.12 12.1-12.12 6.7 0 12.12 5.43 12.12 12.12z"/>
          <text fontSize="18" fontFamily="Arial" id="MarkerAText" y="67.95" x="55.45">A</text>
          <text fontSize="18" fontFamily="Arial" id="MarkerBText" y="26.7" x="109.18">B</text>
        </svg>
        <div>
          <Trans t={this.props.t}>I am a transportation research engineer in Montréal, Québec.        <span>
            <Link to={`/${i18n.language}/c/about`}>Learn more</Link>
              <span> ...or select from a topic below            <svg width="107.67598" height="99.12426" xmlns="http://www.w3.org/2000/svg">
                  <title>PS Arrow</title>
                   <path fill="#d5d7d8" id="arrow" d="m54.09784,94.21057c2.15743,-25.01206 -0.66078,-36.38208 -4.72469,-44.22321c0.20118,-2.46769 0.4187,-3.72961 1.03943,-4.91369c5.49124,5.61837 12.40871,10.42073 12.80394,25.70238c19.52312,-36.44955 22.69681,-82.16835 -54.94829,-45.16816c-4.16416,-4.27905 -6.41729,-7.02242 -8.26823,-11.52827c13.65439,-10.45734 36.28572,-13.54415 45.2154,-14.07962c37.57714,0.0157 49.82987,35.93924 31.84449,69.78385c7.61787,-4.01015 15.17781,-8.14338 24.75745,-7.98475l5.85864,7.27605c-25.71592,5.53033 -37.96587,16.29714 -46.49108,30.04911l-7.08706,-4.91369z"></path>
                </svg>
              </span>
            </span>
          </Trans>
        </div>
      </div>
    );
  }
})
