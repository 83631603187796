import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export default class ExA extends React.Component {

  render(){
    return (
      <a href={this.props.href} target="_blank" rel="noopener noreferrer">{this.props.children}<FontAwesomeIcon className="external fa-2xs" icon={solid('arrow-up-right-from-square')} /></a>
    );
  }

}
