import React from 'react';
import ExA from '../views/basics/exa.js'

export default class MarkdownCompatibleComponent extends React.Component {

  getMarkdownOptions() {
    return {
      forceBlock: true,
      overrides: {
        p: {
          component: 'P',
          props: {
            className: 'ps_pop',
          },
        },
        a: {
          component: ExA,
        }
      },
    }
  }

}
