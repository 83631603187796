import React  from 'react';


export default class SnowGenerator extends React.Component {

  render(){
    return (
      <div className="landing_bg_1" id="snowflakes">
        {[...Array(200).keys()].map((sIx) => (
          <div className="snow"></div>
        ))}
      </div>
    )
  }
}
