import React  from 'react';
import { API, Root, BaseLayout, withRouter, classAggregator } from 'psCMS-common';
import Content from '../content/index';
import Publications from '../publications/index';
import Contact from '../contact/index';
import Links from '../links/index';
import Login from '../admin/login';
import Error from '../error/index';
import { Routes, Route } from "react-router-dom";
import { PSCMS } from './../../index';

export default withRouter(class Main extends classAggregator(Root, API) {

  componentDidMount() {
    //When the page loads, do two things: attempt to grab user data (if logged in), and log this page server side
    this.api('/hits/log', 'POST', {onSuccess:(data) => {this.setState({user:data});}, body:{firstVisit: true, path:this.props.router.location.pathname, lang:this.props.router.params.lang}});
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.api('/hits/log', 'POST', {body:{path:this.props.router.location.pathname, lang:this.props.router.params.lang}});
    }
  }

  logout() {
    this.api('/logout', 'GET', {onSuccess:() => {this.setState({user:null});}});
  }

  render(){
    return (
      <BaseLayout
        PSCMS={PSCMS}
        localGroup={'public'}
        loggedIn={!!this.state.user}
        logout={this.logout.bind(this)}
      >
        <Routes>
          <Route path="c/*" element={<Content />} />
          <Route path="publications/" element={<Publications />} />
          <Route path="contact/" element={<Contact />} />
          <Route path="links/" element={<Links />} />
          <Route path="login/" element={<Login />} />
          <Route path='*' element={<Error status={404} />} />
        </Routes>
      </BaseLayout>
    )
  }
})
