import React  from 'react';

export default class LineFormField extends React.PureComponent {
  static defaultProps = {
    type: 'text',//'password', 'email', 'tel', 'textarea'
    size: 'full',//'reduced'
  };


  checkInput(e) {
    if (e.target.value.length > 0) e.target.classList.add('active');
    else                           e.target.classList.remove('active');
  }


  render(){
    return (
      <div>
        {
          this.props.type==='textarea' ?
            <textarea
              {...this.props}
              className={this.props.size+" ps_lineFormField"}
              onBlur={this.checkInput.bind(this)}
            />
          :
            <input
              {...this.props}
              className={this.props.size+" ps_lineFormField"}
              onBlur={this.checkInput.bind(this)}
            />
        }
        <label htmlFor={this.props.name}>
          {this.props.description}
        </label>
      </div>
    );
  }
}
