import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export default {
	
	// Concern expects the following states
	// sortBy: null, sortDescending: false,

	afterSortCallback() {
		// Method prototype.
	},

	
	renderSortingControls(key, label='') {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={(props) => (
					<Tooltip {...props}>
						{this.state.sortBy==key ?
							this.state.sortDescending ?
							'Sort descending'
							:
							'Sort ascending'
						: 
							'No sorting'
						}
					</Tooltip>
				)}>
				<span 
					onClick={()=>{
						this.setState((this.state.sortBy==key ? {sortDescending: !this.state.sortDescending} : {sortBy: key, sortDescending: false}), this.afterSortCallback)
					}}
					style={{cursor: 'pointer'}}>
					{label} {this.state.sortBy==key ?
						this.state.sortDescending ?
						<FontAwesomeIcon icon={solid('sort-down')} />
						:
						<FontAwesomeIcon icon={solid('sort-up')} />
					: 
						<FontAwesomeIcon icon={solid('sort')} />
					}
				</span>
			</OverlayTrigger>
		)
	}

}
