import React  from 'react';

export default class ContentPlaceholder extends React.Component {

  render(){
    const { style={}, className='', ...otherProps} = this.props
    return (
      <div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={style}>
        <div className="load-wraper">
          <div className="activity"></div>
        </div>
      </div>
    );
  }

}
