import React  from 'react';
import StateFetcher from './stateFetcher.js'
import Sortable from './concerns/sortable';
import Pagination from '../views/widgets/pagination.js'


//Note about extending Paginated: the routing functionality will only work
//if the class that extends Paginated is itself wrapped with withRouter.
//Additionally, the parent element must wrap the extended element with a router
//For example, withRouter(class ListLinks extends Paginated {}) is rendered like:
//<Routes>
//	<Route path=":page?/:sortBy?/:sortDirection?" element={<ListLinks ... />}/>
//</Routes>
class Paginated extends StateFetcher {
	attributeName = '?';
	method = 'POST';
	defaultRequestBody = {pages: true};

	static defaultProps = {
		baseRoutePath: ''
	};


	getCommonState(props) {
		return {
			...super.getCommonState(props),
			data: {},
			activePage: 1,
			activeItem: 0, //Optional use
			selectedItems: [],
			pages: 1,
			sortBy: null,
			sortDescending: false,
			searchText: '',
			customCriteria: {}
		}
	}


	componentDidUpdate(prevProps, prevState) {
		this.parsePaginationFromRoute()
		//manually update the state if the props it is derived from is updated; react won't do this on it's own (and I guess that makes sense)
		//modifications to earchText must always trigger a refresh manually via this.executeSearch() to prevent interrupting the user
		if (prevState.sortBy !== this.state.sortBy || prevState.sortDescending !== this.state.sortDescending || JSON.stringify(prevState.customCriteria) !== JSON.stringify(this.state.customCriteria)) {
			this.refresh(true);
		}
	}


	refresh(keepSortingOptions=false) {
		this.setState({
			...this.getCommonState(this.props),
			...(keepSortingOptions? {sortBy: this.state.sortBy, sortDescending: this.state.sortDescending, searchText: this.state.searchText, customCriteria: this.state.customCriteria} : {})
		},
		() => {
			this.api(
				this.apiURL,
				this.method, {
				onSuccess:this.getSuccessCallback.bind(this),
				body: {...this.defaultRequestBody, ...this.generateSearchQueryParams()}
			})
		})
	}
	

	refreshCurrentPage() {
		this.loadPage(this.state.activePage, true)
	}


	updateCurrentItem(item_data) {this.updatePaginationItem(this.state.activePage, this.state.activeItem, item_data)}
	updatePaginationItem(page, itemId, item_data) {
		this.setState({data: {
			...this.state.data,
			[page]: [
				...this.state.data[page].slice(0, itemId),
				item_data, 
				...this.state.data[page].slice(itemId + 1)
			]
		}});
	}


	updateCurrentItemState(key, value) {this.updatePaginationItemState(this.state.activePage, this.state.activeItem, key, value)}
	updatePaginationItemState(page, itemId, key, value) {
		this.setState({data: {
			...this.state.data,
			[page]: [
				...this.state.data[page].slice(0, itemId),
				{
					...this.state.data[page][itemId],
					[key]:value
				}, 
				...this.state.data[page].slice(itemId + 1)
			]
		}});
	}


	loadPage(page, force=false) {
		this.setState({
			activePage: page
		},
		() => {
			this.updateQueryPath();
			if(!Object.hasOwn(this.state.data, page) || force) {
				this.api(this.apiURL, this.method, {onSuccess:this.getPageLoadSuccessCallback.bind(this), body:this.generateQueryParams(page)});
			}else if(!Object.hasOwn(this.state.data, page+1) && page+1<=this.state.pages) {
				this.api(this.apiURL, this.method, {onSuccess:this.getPageLoadSuccessCallback.bind(this), body:this.generateQueryParams(page+1)});
			}
		})
	}


	generateQueryParams(page) {
		return {page: page, ...this.generateSearchQueryParams()}
	}


	generateSearchQueryParams() {
		return {sortBy: this.state.sortBy, sortDescending: this.state.sortDescending, searchText: this.state.searchText, ...this.state.customCriteria}
	}


	generateQueryPath() {
		return '/'+this.state.activePage+(this.state.sortBy ? '/'+this.state.sortBy+'/'+(this.state.sortDescending ? 'desc' : 'asc') : '');
	}


	updateQueryPath() {
		if(this.props.router) {
			this.props.router.navigate(
				this.props.baseRoutePath.replace(/\/+$/, '')+this.generateQueryPath()
			);
		}
	}


	parsePaginationFromRoute() {
		if(this.props.router?.params?.page) {
			const newPage = parseInt(this.props.router.params.page)
			if(!isNaN(newPage) && (this.state.activePage!=newPage || this.state.sortBy!=this.props.router.params.sortBy || this.state.sortDescending!=this.props.router.params.sortDirection==='desc')) {
				this.setState({
					activePage: newPage,
					sortBy: this.props.router.params.sortBy,
					sortDescending: this.props.router.params.sortDirection==='desc',
				})
			}
		}
	}


	getSuccessCallback(data) {
		this.setState({
			pages: data.pages
		},
		() => {
			this.api(this.apiURL, this.method, {onSuccess:this.getPageLoadSuccessCallback.bind(this), body:this.generateQueryParams(this.state.activePage)});
		})
	}


	getPageLoadSuccessCallback(data) {
		this.setState({
			data: {
				...this.state.data,
				[data.page]: data[this.attributeName]
			}
		},
		() => {
			if(data.page===this.state.activePage && this.state.activePage+1<=this.state.pages) {this.api(this.apiURL, this.method, {onSuccess:this.getPageLoadSuccessCallback.bind(this), body:this.generateQueryParams(this.state.activePage+1)});}
		})
	}


	updateselectedItems(event, id) {
		var { selectedItems } = this.state;
		if(selectedItems.includes(id)) {selectedItems = selectedItems.filter((x) => {return x!==id;});}
		else{selectedItems.push(id);}
		selectedItems.sort();
		this.setState({selectedItems: selectedItems})
	}


	executeSearch() {
		this.refresh(true);
	}


	updateSearch(e) {
		this.setState({searchText:e.target.value});
	}


	afterSortCallback() {
		// Method prototype. Use this to define the default content to display (if children is empty)
		this.updateQueryPath()
	}

	renderPagination() {
		return (
			<Pagination
				activePage={this.state.activePage}
				maxPage={this.state.pages}
				setPage={this.loadPage.bind(this)} 
			/>
		)
	}

}

Object.assign(Paginated.prototype, Sortable, Paginated.prototype); //Paginated.prototype must override some methods of Sortable
export default Paginated