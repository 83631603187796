import React  from 'react';

export default class ContentHeader extends React.PureComponent {
  static defaultProps = {
    title: '',
    subtitle: '',
    dateTime: null,
  };


  render(){
    return (
      <div id="blank_title" className="blank_title ps_pop">
        <div className="blank_title_content">
          <span className="blank_title_header">{this.props.title}</span>
          <h2>{this.props.subtitle}</h2>
        </div>
        {
          this.props.dateTime!==null ?
            <time className="blank_title_details" dateTime={this.props.dateTime}>
              <div className="blank_title_date1">
                <span className="blank_title_day">{this.props.dateTime.getDate()}</span>
              </div>
              <div className="blank_title_date2">
                <span className="blank_title_month">{this.props.dateTime.toLocaleString('en-US', {month: 'short'})}</span>
                <span className="blank_title_year">{this.props.dateTime.getFullYear()}</span>
              </div>
            </time>
          :
            ``
        }
        {this.props.children}
      </div>
    );
  }
}
